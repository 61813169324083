import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faMobile } from "@fortawesome/free-solid-svg-icons";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
// import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import Container from "../../../components/common/Container";
import DefaultAnimation from "../../../components/common/DefaultAnimation";
import { useSelector } from "react-redux";

const Service = () => {
    const { lang_obj } = useSelector((state) => state.lang);

    return (
        <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
            <Container>
                <DefaultAnimation>
                    <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                        <div className="flex justify-center items-center w-fit ">
                            <h2>{lang_obj.ContactUs}</h2>
                        </div>
                    </div>

                    <div className="flex flex-col justify-center md:flex-row md:mx-0 sm:mx-0 md:gap-[100px] xl:gap-[500px]">
                        <div className="flex flex-col justify-center mt-8 md:mt-0 mb-10">
                            <ul className="flex gap-3 mb-5">
                                <li>
                                    <span className="mr-2">
                                        <FontAwesomeIcon icon={faMapMarker} className="text-blue-500 text-3xl" />
                                    </span>
                                </li>
                                <li>
                                    <p className="text-bold text-[#0072BC] text-2xl">{lang_obj.Address}</p>
                                </li>
                            </ul>
                            <ul className="flex gap-3 mb-2">
                                <li>
                                    <p className="text-bold text-[#0072BC] text-xl">{lang_obj.Headquarters}</p>
                                </li>
                            </ul>
                            <ul className="flex gap-3 mb-5">
                                <li>
                                    JL. Kancil V Blok C No. 43,
                                    <br />
                                    Cikarang Baru, Kelurahan Sertajaya - Cikarang Timur
                                    <br />
                                    Bekasi, Jawa Barat 17550
                                </li>
                            </ul>
                            <ul className="flex gap-3 mb-2">
                                <li>
                                    <p className="text-bold text-[#0072BC] text-xl">{lang_obj.Workshop}</p>
                                </li>
                            </ul>
                            <ul className="flex gap-3 mb-5">
                                <li>
                                    Jl. Tanah Merah,
                                    <br />
                                    Desa Karangsambung, Kedung Waringin,
                                    <br />
                                    Bekasi - Jawa Barat
                                </li>
                            </ul>
                        </div>

                        <div className="flex flex-col gap-5 mb-10">
                            <ul>
                                <li className="hover:text-[#6EB5CB]">
                                    <a href={`tel:021-89325054`} className="whitespace-nowrap">
                                        <span className="mr-2">
                                            <FontAwesomeIcon icon={faPhone} className="text-blue-500 text-3xl" />
                                        </span>
                                        021-89325054
                                    </a>
                                </li>
                            </ul>
                            <ul>
                                <li className="hover:text-[#6EB5CB]">
                                    <a href={`tel:081298889729`} className="whitespace-nowrap">
                                        <span className="mr-2">
                                            <FontAwesomeIcon icon={faMobile} className="text-blue-500 text-3xl" />
                                        </span>
                                        081298889729
                                    </a>
                                </li>
                            </ul>
                            <ul>
                                <li className="hover:text-[#6EB5CB]">
                                    <a href={`mailto:blastingpaintingrpp@gmail.com`} className="whitespace-nowrap">
                                        <span className="mr-2">
                                            <FontAwesomeIcon icon={faEnvelope} className="text-blue-500 text-3xl" />
                                        </span>
                                        blastingpaintingrpp@gmail.com
                                    </a>
                                </li>
                            </ul>
                            <ul>
                                <li className="hover:text-[#6EB5CB]">
                                    <a href={`mailto:jonner.rpp@gmail.com`} className="whitespace-nowrap">
                                        <span className="mr-2">
                                            <FontAwesomeIcon icon={faEnvelope} className="text-blue-500 text-3xl" />
                                        </span>
                                        jonner.rpp@gmail.com
                                    </a>
                                </li>
                            </ul>
                            <ul>
                                <li className="hover:text-[#6EB5CB]">
                                    <a href={`mailto:rima.rpp@gmail.com`} className="whitespace-nowrap">
                                        <span className="mr-2">
                                            <FontAwesomeIcon icon={faEnvelope} className="text-blue-500 text-3xl" />
                                        </span>
                                        rima.rpp@gmail.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {/* <div>
                            <ul className="flex gap-3 mb-5 mt-10">
                                <li>
                                    <span className="mr-2">
                                        <FontAwesomeIcon icon={faCreditCard} className="text-blue-500 text-3xl" />
                                    </span>
                                </li>
                                <li>
                                    <p className="text-bold text-[#0072BC] text-2xl">{lang_obj.AccountNumber}</p>
                                </li>
                            </ul>
                            <ul className="flex gap-3 mb-2">
                                <li>
                                    <img src="/logo/lg_bca.png" alt="bca-logo" />
                                </li>
                                <li className="ml-4">
                                    8730149272
                                    <br />
                                    PT Ramos Putra Pertama
                                </li>
                            </ul>
                            <ul className="flex gap-3 mb-2">
                                <li>
                                    <img src="/logo/lg_bni.png" alt="bni-logo" />
                                </li>
                                <li className="ml-2">
                                    7272721997
                                    <br />
                                    PT Ramos Putra Pertama
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </DefaultAnimation>
            </Container>
        </section>
    );
};

export default Service;
