import React from "react";
import { Link } from "react-router-dom";
import Container from "../../../components/common/Container";
import DefaultAnimation from "../../../components/common/DefaultAnimation";
import { useSelector } from "react-redux";

const Service = () => {
    const { lang_obj } = useSelector((state) => state.lang);

    return (
        <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
            <Container>
                <DefaultAnimation>
                    <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                        <div className="flex justify-center items-center w-fit ">
                            <h2>{lang_obj.Sandblasting}</h2>
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-center md:flex-row">
                        <p className="text-justify">
                            {lang_obj.SandblastingDesc}
                            <br />
                            <br />
                            {lang_obj.SandblastingDesc2}
                        </p>
                    </div>

                    <div className="flex flex-col items-center justify-center xl:flex-row mt-10 gap-8">
                        <div className="flex flex-col">
                            <ul>
                                <li>
                                    <img src="/images/page_product/Elcometer.png" alt="Elcometer" />
                                </li>
                            </ul>
                        </div>
                        <div className="flex flex-col max-w-[400px] min-w-[400px]">
                            <ul>
                                <li>
                                    <p className="font-bold">
                                        {lang_obj.Elcometer}
                                    </p>
                                </li>
                                <li>
                                    <p className="mt-4">
                                        {lang_obj.ElcometerDesc}
                                    </p>
                                </li>
                            </ul>
                        </div>

                        <div className="flex flex-col">
                            <ul>
                                <li>
                                    <img src="/images/page_product/Eschenbach.png" alt="Eschenbach" />
                                </li>
                            </ul>
                        </div>
                        <div className="flex flex-col max-w-[400px] min-w-[400px]">
                            <ul>
                                <li>
                                    <p className="font-bold">
                                        {lang_obj.EschenbachOptic}
                                    </p>
                                </li>
                                <li>
                                    <p className="mt-4">
                                        {lang_obj.EschenbachOpticDesc}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-center xl:flex-row mt-10 gap-8">
                        <div className="flex flex-col">
                            <ul>
                                <li>
                                    <img src="/images/page_product/Elcometer_Steel.png" alt="Elcometer_Steel" />
                                </li>
                            </ul>
                        </div>
                        <div className="flex flex-col max-w-[400px] min-w-[400px]">
                            <ul>
                                <li>
                                    <p className="font-bold">
                                        {lang_obj.ElcometerSteel}
                                    </p>
                                </li>
                                <li>
                                    <p className="mt-4">
                                        {lang_obj.ElcometerSteelDesc}
                                    </p>
                                </li>
                            </ul>
                        </div>

                        <div className="flex flex-col">
                            <ul>
                                <li>
                                    <img src="/images/page_product/Thickness.png" alt="Thickness" />
                                </li>
                            </ul>
                        </div>
                        <div className="flex flex-col max-w-[400px] min-w-[400px]">
                            <ul>
                                <li>
                                    <p className="font-bold">
                                        {lang_obj.Thickness}
                                    </p>
                                </li>
                                <li>
                                    <p className="mt-4">
                                        {lang_obj.ThicknessDesc}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-center xl:flex-row mt-10 gap-8">
                        <div className="flex flex-col">
                            <ul>
                                <li>
                                    <img src="/images/page_product/Blasting_Profile.png" alt="Blasting_Profile" />
                                </li>
                            </ul>
                        </div>
                        <div className="flex flex-col max-w-[400px] min-w-[400px]">
                            <ul>
                                <li>
                                    <p className="font-bold">
                                        {lang_obj.BlastingProfile}
                                    </p>
                                </li>
                                <li>
                                    <p className="mt-4">
                                        {lang_obj.BlastingProfileDesc}
                                    </p>
                                </li>
                            </ul>
                        </div>

                        <div className="flex flex-col">
                            <ul>
                                <li>
                                    <img src="/images/page_product/Whirling.png" alt="Whirling" />
                                </li>
                            </ul>
                        </div>
                        <div className="flex flex-col max-w-[400px] min-w-[400px]">
                            <ul>
                                <li>
                                    <p className="font-bold">
                                        {lang_obj.Whirling}
                                    </p>
                                </li>
                                <li>
                                    <p className="mt-4">
                                        {lang_obj.WhirlingDesc}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <Link to="/contact"><button className="text-white left-10 rounded-full bg-blue-900 bg-opacity-75 h-[50px] w-[150px] mt-8 hover:bg-indigo-900">{lang_obj.ContactUs}</button></Link>
                </DefaultAnimation>
            </Container>
        </section>
    );
};

export default Service;
