import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPosts, getPostsById } from "../../api/posts.api";

export const fetchpost = createAsyncThunk("todos/fetchposts", async (payload) => {
  const res = await getPosts(payload.page, payload.limit);
  return res;
});

export const fetchpostbyid = createAsyncThunk("todos/fetchbyid", async (payload) => {
  const res = await getPostsById(payload.id);
  return res;
});

export const addpost = createAsyncThunk("todos/addpost", async (payload) => {
  const res = await addpost({
    userId: payload.userid,
    title: payload.title,
    completed: payload.complete,
  });
  return res;
});
