import { createAsyncThunk } from "@reduxjs/toolkit";
import { addTodos, getTodos, getTodosById } from "../../api/todos.api";

export const fetchtodo = createAsyncThunk("todos/fetchtodos", async (payload) => {
  const res = await getTodos(payload.page, payload.limit);
  return res;
});

export const fetchtodobyid = createAsyncThunk("todos/fetchbyid", async (payload) => {
  const res = await getTodosById(payload.id);
  return res;
});

export const addtodos = createAsyncThunk("todos/addtodo", async (payload) => {
  const res = await addTodos({
    userid: payload.userid,
    title: payload.title,
    completed: payload.complete,
  });
  return res;
});
