import React from "react";
import Container from "../../../components/common/Container";
import DefaultAnimation from "../../../components/common/DefaultAnimation";
import { useSelector } from "react-redux";

const Service = () => {
    const { lang_obj } = useSelector((state) => state.lang);

    return (
        <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
            <Container>
                <DefaultAnimation>
                    <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                        <div className="flex justify-center items-center w-fit ">
                            <h2>{lang_obj.Abouts}</h2>
                        </div>
                    </div>

                    <div className="bg-white mx-0.5 container flex justify-center gap-8 p-4 flex-wrap m-auto">
                        <p className="text-justify">
                            <img src="/images/page_about/jonner.png" alt="PhotoProfile" className="float-left mr-4 w-[130px]" />
                            {lang_obj.AboutParagraph}
                            <br />
                            <br />
                            {lang_obj.AboutParagraph2}
                            <br />
                            <br />
                            {lang_obj.AboutParagraph3}
                        </p>
                    </div>

                    <div className="flex flex-col items-center justify-between md:flex-row xl:mx-64 lg:mx-24 md:mx-0 sm:mx-0 mt-20 gap-2">
                        <div className="flex flex-col mb-10 text-center h-[200px] w-[300px] border-4 border-blue-violet">
                            <div className="flex flex-col items-center bg-blue-violet">
                                <h4 className="text-2xl m-3 font-bold text-white">{lang_obj.Vision}</h4>
                            </div>
                            <p className="mt-5">
                                {lang_obj.Visions}
                            </p>
                        </div>

                        <div className="flex flex-col mb-10 text-center h-[200px] w-[300px] border-4 border-blue-violet">
                            <div className="flex flex-col items-center bg-blue-violet">
                                <h4 className="text-2xl m-3 font-bold text-white">{lang_obj.Mission}</h4>
                            </div>
                            <p className="mt-5">
                                {lang_obj.Missions}
                            </p>
                        </div>
                    </div>
                </DefaultAnimation>
            </Container>
        </section>
    );
};

export default Service;
