import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";

import { motion } from "framer-motion";

const FloatingBtn = () => {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = (e) => {
      if (window.scrollY > 424) {
        setShow(true);
      } else setShow(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="fixed bottom-0 right-0 z-[100]">
      <div className="flex justify-center items-center mr-[30px] mb-[30px]">
        <motion.div initial={{ y: 150 }} animate={{ y: 0, x: show ? 0 : 50 }} transition={{ type: "just" }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wa.me/6281298889729/?text=Hai,%20saya%20mendapatkan%20informasi%20WhatsApp%20Anda%20dari%20situs%20web%20Anda.%0A%0Aramosputrapertama.com"
          >
            <img
              src={process.env.PUBLIC_URL + "/icons/icon_wa.png"}
              alt="wa_logo"
              className={`h-[40px] md:h-[55px] text-slate-400 hover:text-blue-500 transition-colors drop-shadow hover:drop-shadow-md opacity-75`}
            />
          </a>
        </motion.div>

        <motion.div initial={{ y: 150 }} animate={{ y: show ? 0 : 150 }} transition={{ type: "just" }}>
          <a href="#top" className=" rounded-full opacity-75">
            <FontAwesomeIcon
              icon={faArrowCircleUp}
              className={`w-[40px] md:w-[35px] h-[50px] md:h-[35px] text-slate-400 hover:text-blue-500 transition-colors ml-[10px] drop-shadow`}
            />
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default FloatingBtn;
