import React from "react";
import { motion } from "framer-motion";

import { AiFillLeftCircle } from "react-icons/ai";
import { AiFillRightCircle } from "react-icons/ai";

const arrayBanner = [
    {
        title: "Certificate",
        url: "/images/page_legality/group_aksi.png",
        name: "SERTIFIKAT AKSI"
    },
    {
        title: "Certificate",
        url: "/images/page_legality/group_badan_usaha.png",
        name: "SERTIFIKAT BADAN USAHA"
    },
    {
        title: "Certificate",
        url: "/images/page_legality/group_bangunan.png",
        name: "AHLI TEKNIK BANGUNAN GEDUNG - MUDA"
    },
    {
        title: "Certificate",
        url: "/images/page_legality/group_jalan.png",
        name: "SERTIFIKAT AHLI TEKNIK JALAN - MUDA"
    },
    {
        title: "Certificate",
        url: "/images/page_legality/group_plambing.png",
        name: "AHLI TEKNIK PLAMBING & POMPA MEKANIK - MUDA"
    },
];

const MySLiderBanner = ({ duration = 1 }) => {
    const [widthcomp, setwidthcomp] = React.useState(window.innerWidth);

    const [animation, setanimation] = React.useState("standby");
    const [availableanimation, setavailableanimation] = React.useState(true);

    const [bannerMain, setBannerMain] = React.useState(0);
    const [banner01, setBanner01] = React.useState(arrayBanner.length - 1);
    const [banner02, setBanner02] = React.useState(1);

    React.useEffect(() => {
        const setWidthWindow = () => setwidthcomp(window.innerWidth);
        window.addEventListener("resize", setWidthWindow);
        return () => window.removeEventListener("resize", setWidthWindow);
    }, []);

    React.useEffect(() => {
        const animateInterfal = setInterval(() => {
            setanimation("right");
        }, (duration + 5) * 1000);
        return () => clearInterval(animateInterfal);
    });

    const handleAnimationRight = () => {
        if (animation !== "hidden") {
            setTimeout(() => {
                setanimation("hidden");
                setBannerMain(bannerMain < arrayBanner.length - 1 ? bannerMain + 1 : 0);
                setTimeout(() => {
                    setBanner01(banner01 < arrayBanner.length - 1 ? banner01 + 1 : 0);
                    setBanner02(banner02 < arrayBanner.length - 1 ? banner02 + 1 : 0);
                    setavailableanimation(true);
                }, 10);
            }, duration * 500);
        }
    };

    const handleAnimationLeft = () => {
        if (animation !== "hidden") {
            setTimeout(() => {
                setanimation("hidden");
                setBannerMain(bannerMain > 0 ? bannerMain - 1 : arrayBanner.length - 1);
                setTimeout(() => {
                    setBanner01(banner01 > 0 ? banner01 - 1 : arrayBanner.length - 1);
                    setBanner02(banner02 > 0 ? banner02 - 1 : arrayBanner.length - 1);
                    setavailableanimation(true);
                }, 10);
            }, duration * 500);
        }
    };

    const variants = {
        fromLeft: {
            x: widthcomp,
            transition: { type: "tween", ease: "easeInOut", duration: duration * 0.7 },
        },
        fromRight: {
            x: widthcomp * -1,
            transition: { type: "tween", ease: "easeInOut", duration: duration * 0.7 },
        },
        hidden: {
            x: 0,
            opacity: 0,
            transition: { duration: 0 },
        },
        stanby: {
            opacity: 1,
            x: 0,
            transition: { duration: 0 },
        },
    };

    return (
        <div className="relative h-[270px] md:h-fit md:max-h-[550px] flex justify-center items-center overflow-hidden">
            <motion.div
                initial="stanby"
                animate={animation === "left" ? "fromLeft" : "hidden"}
                variants={variants}
                onAnimationComplete={() => animation === "left" && handleAnimationLeft()}
                className="shrink-0 w-screen z-10"
            >
                <img src={arrayBanner[banner01].url} alt={arrayBanner[banner01].title} className="w-screen object-cover" />
                <p className="absolute top-5 left-10 font-bold text-l">{arrayBanner[banner01].name}</p>
            </motion.div>
            <div className="shrink-0 w-screen z-0">
                <img src={arrayBanner[bannerMain].url} alt={arrayBanner[bannerMain].title} className="w-screen object-cover" />
                <p className="absolute top-5 left-10 font-bold text-l">{arrayBanner[bannerMain].name}</p>
            </div>
            <motion.div
                initial="stanby"
                animate={animation === "right" ? "fromRight" : "hidden"}
                variants={variants}
                onAnimationComplete={() => animation === "right" && handleAnimationRight()}
                className="shrink-0 w-screen z-10"
            >
                <img src={arrayBanner[banner02].url} alt={arrayBanner[banner02].title} className="w-screen object-cover" />
            </motion.div>
            <div className="flex justify-center items-center h-[25px] w-[25px] md:h-[35px] md:w-[35px] lg:h-[55px] lg:w-[55px] rounded-full absolute mx-4 md:mx-10 z-50 left-0">
                <AiFillLeftCircle
                    size={50}
                    className={
                        availableanimation
                            ? `opacity-80 text-[#1d3f74] hover:cursor-pointer hover:text-[#0072bc] hover:opacity-100 transition-colors ease-in`
                            : `opacity-80 text-[#1d3f74] transition-colors ease-in`
                    }
                    onClick={() => {
                        if (availableanimation) {
                            setavailableanimation(false);
                            setanimation("left");
                        }
                    }}
                />
            </div>
            <div className="flex justify-center items-center h-[25px] w-[25px] md:h-[35px] md:w-[35px] lg:h-[55px] lg:w-[55px] rounded-full absolute mx-4 md:mx-10 z-50 right-0">
                <AiFillRightCircle
                    size={50}
                    className={
                        availableanimation
                            ? `opacity-80 text-[#1d3f74] hover:cursor-pointer hover:text-[#0072bc] hover:opacity-100 transition-colors ease-in`
                            : `opacity-80 text-[#1d3f74] transition-colors ease-in`
                    }
                    onClick={() => {
                        if (availableanimation) {
                            setavailableanimation(false);
                            setanimation("right");
                        }
                    }}
                />
            </div>
            <div className="absolute flex justify-center items-center gap-2 bottom-5 md:bottom-[30px] w-full z-30">
                {arrayBanner.map((_, index) => (
                    <div
                        key={index}
                        className={`${bannerMain === index ? "bg-[#0072bc] border-none" : ""
                            } md:h-[15px] h-[10px] md:w-[15px] w-[10px] rounded-full  border-gray-500 border-[2px] md:border-[3px] hover:border-[#0072bc] `}
                    ></div>
                ))}
            </div>
        </div>
    );
};

const Banner = () => {
    return (
        <section id="top" className="bg-slate-100 flex justify-center items-center w-screen">
            <MySLiderBanner arrayBanner={arrayBanner} />
        </section>
    );
};

export default Banner;
