import { createAsyncThunk } from "@reduxjs/toolkit";
import language from "../../data/language.json";

export const getLangObj = createAsyncThunk("lang/get", async (payload) => {
  const langfromStorage = localStorage.getItem("language") || "ID";
  const langObj = language[langfromStorage];
  return { langObj, lang: langfromStorage };
});

export const setLang = createAsyncThunk("lang/set", async (payload) => {
  localStorage.setItem("language", payload);
  const langObj = language[payload || "ID"];
  return { langObj, lang: payload };
});
