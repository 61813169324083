import React from "react";
import { Link } from "react-router-dom";
import Container from "../../../components/common/Container";
import DefaultAnimation from "../../../components/common/DefaultAnimation";
import { useSelector } from "react-redux";

const Service = () => {
    const { lang_obj } = useSelector((state) => state.lang);

    return (
        <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
            <Container>
                <DefaultAnimation>
                    <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                        <div className="flex justify-center items-center w-fit ">
                            <h2>{lang_obj.Constractor}</h2>
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-center md:flex-row">
                        <p className="text-justify">
                            {lang_obj.ConstractorDesc}
                            <br />
                            <br />
                            1. SI002
                            Jasa Pelaksana Konstruksi Instalasi Pengolahan Air Minum dan Air Limbah Serta Bangunan Pengolahan Sampah.
                            <br />
                            2. SI003
                            Jasa Pelaksana Untuk Konstruksi Jalan Raya (kecuali jalan layang), Jalan, Rel Kereta A, dan Landas Pacu Bandara.
                            <br />
                            3. MK002
                            Jasa Pelaksana Konstruksi Pemasangan Pipa Air (Plumping) dalam Bangunan dan Salurannya.
                            <br />
                            4. MK010
                            Jasa Pelaksana instalasi fasilitas produksi, penyimpanan minyak dan gas (pekerjaan rekayasa).
                        </p>
                    </div>

                    <Link to="/contact"><button className="text-white left-10 rounded-full bg-blue-900 bg-opacity-75 h-[50px] w-[150px] mt-8 hover:bg-indigo-900">{lang_obj.ContactUs}</button></Link>
                </DefaultAnimation>
            </Container>
        </section>
    );
};

export default Service;
