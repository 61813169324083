import React from "react";
import { motion, useInView } from "framer-motion";

const DefaultAnimation = ({ children }) => {
  const ref = React.useRef(null);
  const inView = useInView(ref, { threshold: 0.2 });

  const variants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
    hidden: {
      y: 150,
      opacity: 0,
    },
  };
  return (
    <motion.div
      ref={ref}
      animate={inView ? "visible" : "hidden"}
      initial="hidden"
      transition={{ delay: 0.5 }}
      variants={variants}
    >
      {children}
    </motion.div>
  );
};

export default DefaultAnimation;
