import React from "react";

const Container = ({ children, bg }) => {
  return (
    <div className={`flex first-letter:justify-center items-start py-[2rem] md:py-[3rem] ${bg}`}>
      <div className="container m-auto">
        <div className="mx-[30px] sm:mx-[80px] md:mx-[95px]">{children}</div>
      </div>
    </div>
  );
};

export default Container;
