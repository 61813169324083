import React, { useState } from "react";
import Container from "../../../components/common/Container";
import DefaultAnimation from "../../../components/common/DefaultAnimation";
import { useSelector } from "react-redux";

const Service = () => {
    const { lang_obj } = useSelector((state) => state.lang);
    const [project, setProject] = useState("angkasa-pura");

    if (project === 'angkasa-pura') {
        return (
            <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
                <Container>
                    <DefaultAnimation>
                        <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                            <div className="flex justify-center items-center w-fit ">
                                <h2>{lang_obj.ProjectGalery}</h2>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-20">
                            <div className="flex flex-col md:text-left text-center w-[350px] border-r-4 border-blue-violet gap-5">
                                <div className="flex flex-col cursor-pointer hover:text-[#6EB5CB] font-bold" onClick={() => setProject("angkasa-pura")}>
                                    Angkasa Pura II
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("check-dft")}>
                                        Check dft of Roughness Profile
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cool-cikarang-listrindo")}>
                                        Cooling Tower Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("fresh-water")}>
                                        Fresh Water Tank Angkasa Pura II
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press-internal")}>
                                        Press Film Process Internal Tank
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press")}>
                                        Press Film Process
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cikarang-listrindo")}>
                                        Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cbs")}>
                                        CBS Marunda Center
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("pundi")}>
                                        Silo PT. Pundi Kencana
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("moni-narogong")}>
                                        Storage Tank PT. Moni Narogong
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("lubuk-gaung")}>
                                        Storage Tank Smart Tbk Lubuk Gaung
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("marunda")}>
                                        Storage Tank Smart Tbk Marunda
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("dover")}>
                                        PT. Dover Chemical
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col md:text-left text-center gap-5 justify-center items-center">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/ap.png" alt="ap" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/ap2.png" alt="ap2" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/ap3.png" alt="ap3" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/ap4.png" alt="ap4" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/ap5.png" alt="ap5" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/ap6.png" alt="ap6" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DefaultAnimation>
                </Container>
            </section>
        );
    } else if (project === 'check-dft') {
        return (
            <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
                <Container>
                    <DefaultAnimation>
                        <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                            <div className="flex justify-center items-center w-fit ">
                                <h2>{lang_obj.ProjectGalery}</h2>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-20">
                            <div className="flex flex-col md:text-left text-center w-[350px] border-r-4 border-blue-violet gap-5">
                                <div className="flex flex-col cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("angkasa-pura")}>
                                    Angkasa Pura II
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB] font-bold" onClick={() => setProject("check-dft")}>
                                        Check dft of Roughness Profile
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cool-cikarang-listrindo")}>
                                        Cooling Tower Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("fresh-water")}>
                                        Fresh Water Tank Angkasa Pura II
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press-internal")}>
                                        Press Film Process Internal Tank
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press")}>
                                        Press Film Process
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cikarang-listrindo")}>
                                        Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cbs")}>
                                        CBS Marunda Center
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("pundi")}>
                                        Silo PT. Pundi Kencana
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("moni-narogong")}>
                                        Storage Tank PT. Moni Narogong
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("lubuk-gaung")}>
                                        Storage Tank Smart Tbk Lubuk Gaung
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("marunda")}>
                                        Storage Tank Smart Tbk Marunda
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("dover")}>
                                        PT. Dover Chemical
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col md:text-left text-center gap-5 justify-center items-center">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/dft.png" alt="dft" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DefaultAnimation>
                </Container>
            </section>
        );
    } else if (project === 'cool-cikarang-listrindo') {
        return (
            <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
                <Container>
                    <DefaultAnimation>
                        <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                            <div className="flex justify-center items-center w-fit ">
                                <h2>{lang_obj.ProjectGalery}</h2>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-20">
                            <div className="flex flex-col md:text-left text-center w-[350px] border-r-4 border-blue-violet gap-5">
                                <div className="flex flex-col cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("angkasa-pura")}>
                                    Angkasa Pura II
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("check-dft")}>
                                        Check dft of Roughness Profile
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB] font-bold" onClick={() => setProject("cool-cikarang-listrindo")}>
                                        Cooling Tower Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("fresh-water")}>
                                        Fresh Water Tank Angkasa Pura II
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press-internal")}>
                                        Press Film Process Internal Tank
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press")}>
                                        Press Film Process
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cikarang-listrindo")}>
                                        Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cbs")}>
                                        CBS Marunda Center
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("pundi")}>
                                        Silo PT. Pundi Kencana
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("moni-narogong")}>
                                        Storage Tank PT. Moni Narogong
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("lubuk-gaung")}>
                                        Storage Tank Smart Tbk Lubuk Gaung
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("marunda")}>
                                        Storage Tank Smart Tbk Marunda
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("dover")}>
                                        PT. Dover Chemical
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col md:text-left text-center gap-5 justify-center items-center">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/ckrli.png" alt="ckrli" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DefaultAnimation>
                </Container>
            </section>
        );
    } else if (project === 'fresh-water') {
        return (
            <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
                <Container>
                    <DefaultAnimation>
                        <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                            <div className="flex justify-center items-center w-fit ">
                                <h2>{lang_obj.ProjectGalery}</h2>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-20">
                            <div className="flex flex-col md:text-left text-center w-[350px] border-r-4 border-blue-violet gap-5">
                                <div className="flex flex-col cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("angkasa-pura")}>
                                    Angkasa Pura II
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("check-dft")}>
                                        Check dft of Roughness Profile
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cool-cikarang-listrindo")}>
                                        Cooling Tower Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB] font-bold" onClick={() => setProject("fresh-water")}>
                                        Fresh Water Tank Angkasa Pura II
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press-internal")}>
                                        Press Film Process Internal Tank
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press")}>
                                        Press Film Process
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cikarang-listrindo")}>
                                        Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cbs")}>
                                        CBS Marunda Center
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("pundi")}>
                                        Silo PT. Pundi Kencana
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("moni-narogong")}>
                                        Storage Tank PT. Moni Narogong
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("lubuk-gaung")}>
                                        Storage Tank Smart Tbk Lubuk Gaung
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("marunda")}>
                                        Storage Tank Smart Tbk Marunda
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("dover")}>
                                        PT. Dover Chemical
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col md:text-left text-center gap-5 justify-center items-center">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/fwap.png" alt="fwap" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DefaultAnimation>
                </Container>
            </section>
        );
    } else if (project === 'press-internal') {
        return (
            <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
                <Container>
                    <DefaultAnimation>
                        <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                            <div className="flex justify-center items-center w-fit ">
                                <h2>{lang_obj.ProjectGalery}</h2>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-20">
                            <div className="flex flex-col md:text-left text-center w-[350px] border-r-4 border-blue-violet gap-5">
                                <div className="flex flex-col cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("angkasa-pura")}>
                                    Angkasa Pura II
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("check-dft")}>
                                        Check dft of Roughness Profile
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cool-cikarang-listrindo")}>
                                        Cooling Tower Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("fresh-water")}>
                                        Fresh Water Tank Angkasa Pura II
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB] font-bold" onClick={() => setProject("press-internal")}>
                                        Press Film Process Internal Tank
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press")}>
                                        Press Film Process
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cikarang-listrindo")}>
                                        Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cbs")}>
                                        CBS Marunda Center
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("pundi")}>
                                        Silo PT. Pundi Kencana
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("moni-narogong")}>
                                        Storage Tank PT. Moni Narogong
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("lubuk-gaung")}>
                                        Storage Tank Smart Tbk Lubuk Gaung
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("marunda")}>
                                        Storage Tank Smart Tbk Marunda
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("dover")}>
                                        PT. Dover Chemical
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col md:text-left text-center gap-5 justify-center items-center">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/pressinter.png" alt="pressinter" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DefaultAnimation>
                </Container>
            </section>
        );
    } else if (project === 'press') {
        return (
            <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
                <Container>
                    <DefaultAnimation>
                        <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                            <div className="flex justify-center items-center w-fit ">
                                <h2>{lang_obj.ProjectGalery}</h2>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-20">
                            <div className="flex flex-col md:text-left text-center w-[350px] border-r-4 border-blue-violet gap-5">
                                <div className="flex flex-col cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("angkasa-pura")}>
                                    Angkasa Pura II
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("check-dft")}>
                                        Check dft of Roughness Profile
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cool-cikarang-listrindo")}>
                                        Cooling Tower Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("fresh-water")}>
                                        Fresh Water Tank Angkasa Pura II
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press-internal")}>
                                        Press Film Process Internal Tank
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB] font-bold" onClick={() => setProject("press")}>
                                        Press Film Process
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cikarang-listrindo")}>
                                        Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cbs")}>
                                        CBS Marunda Center
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("pundi")}>
                                        Silo PT. Pundi Kencana
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("moni-narogong")}>
                                        Storage Tank PT. Moni Narogong
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("lubuk-gaung")}>
                                        Storage Tank Smart Tbk Lubuk Gaung
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("marunda")}>
                                        Storage Tank Smart Tbk Marunda
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("dover")}>
                                        PT. Dover Chemical
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col md:text-left text-center gap-5 justify-center items-center">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/press.png" alt="press" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DefaultAnimation>
                </Container>
            </section>
        );
    } else if (project === 'cikarang-listrindo') {
        return (
            <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
                <Container>
                    <DefaultAnimation>
                        <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                            <div className="flex justify-center items-center w-fit ">
                                <h2>{lang_obj.ProjectGalery}</h2>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-20">
                            <div className="flex flex-col md:text-left text-center w-[350px] border-r-4 border-blue-violet gap-5">
                                <div className="flex flex-col cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("angkasa-pura")}>
                                    Angkasa Pura II
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("check-dft")}>
                                        Check dft of Roughness Profile
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cool-cikarang-listrindo")}>
                                        Cooling Tower Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("fresh-water")}>
                                        Fresh Water Tank Angkasa Pura II
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press-internal")}>
                                        Press Film Process Internal Tank
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press")}>
                                        Press Film Process
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB] font-bold" onClick={() => setProject("cikarang-listrindo")}>
                                        Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cbs")}>
                                        CBS Marunda Center
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("pundi")}>
                                        Silo PT. Pundi Kencana
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("moni-narogong")}>
                                        Storage Tank PT. Moni Narogong
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("lubuk-gaung")}>
                                        Storage Tank Smart Tbk Lubuk Gaung
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("marunda")}>
                                        Storage Tank Smart Tbk Marunda
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("dover")}>
                                        PT. Dover Chemical
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col md:text-left text-center gap-5 justify-center items-center">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/ckrg.png" alt="ckrg" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/ckrg2.png" alt="ckrg" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/ckrg3.png" alt="ckrg" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/ckrg4.png" alt="ckrg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DefaultAnimation>
                </Container>
            </section>
        );
    } else if (project === 'cbs') {
        return (
            <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
                <Container>
                    <DefaultAnimation>
                        <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                            <div className="flex justify-center items-center w-fit ">
                                <h2>{lang_obj.ProjectGalery}</h2>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-20">
                            <div className="flex flex-col md:text-left text-center w-[350px] border-r-4 border-blue-violet gap-5">
                                <div className="flex flex-col cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("angkasa-pura")}>
                                    Angkasa Pura II
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("check-dft")}>
                                        Check dft of Roughness Profile
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cool-cikarang-listrindo")}>
                                        Cooling Tower Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("fresh-water")}>
                                        Fresh Water Tank Angkasa Pura II
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press-internal")}>
                                        Press Film Process Internal Tank
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press")}>
                                        Press Film Process
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cikarang-listrindo")}>
                                        Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB] font-bold" onClick={() => setProject("cbs")}>
                                        CBS Marunda Center
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("pundi")}>
                                        Silo PT. Pundi Kencana
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("moni-narogong")}>
                                        Storage Tank PT. Moni Narogong
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("lubuk-gaung")}>
                                        Storage Tank Smart Tbk Lubuk Gaung
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("marunda")}>
                                        Storage Tank Smart Tbk Marunda
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("dover")}>
                                        PT. Dover Chemical
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col md:text-left text-center gap-5 justify-center items-center">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/cbs.png" alt="cbs" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DefaultAnimation>
                </Container>
            </section>
        );
    } else if (project === 'pundi') {
        return (
            <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
                <Container>
                    <DefaultAnimation>
                        <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                            <div className="flex justify-center items-center w-fit ">
                                <h2>{lang_obj.ProjectGalery}</h2>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-20">
                            <div className="flex flex-col md:text-left text-center w-[350px] border-r-4 border-blue-violet gap-5">
                                <div className="flex flex-col cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("angkasa-pura")}>
                                    Angkasa Pura II
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("check-dft")}>
                                        Check dft of Roughness Profile
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cool-cikarang-listrindo")}>
                                        Cooling Tower Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("fresh-water")}>
                                        Fresh Water Tank Angkasa Pura II
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press-internal")}>
                                        Press Film Process Internal Tank
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press")}>
                                        Press Film Process
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cikarang-listrindo")}>
                                        Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cbs")}>
                                        CBS Marunda Center
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB] font-bold" onClick={() => setProject("pundi")}>
                                        Silo PT. Pundi Kencana
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("moni-narogong")}>
                                        Storage Tank PT. Moni Narogong
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("lubuk-gaung")}>
                                        Storage Tank Smart Tbk Lubuk Gaung
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("marunda")}>
                                        Storage Tank Smart Tbk Marunda
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("dover")}>
                                        PT. Dover Chemical
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col md:text-left text-center gap-5 justify-center items-center">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/silo.png" alt="silo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DefaultAnimation>
                </Container>
            </section>
        );
    } else if (project === 'moni-narogong') {
        return (
            <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
                <Container>
                    <DefaultAnimation>
                        <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                            <div className="flex justify-center items-center w-fit ">
                                <h2>{lang_obj.ProjectGalery}</h2>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-20">
                            <div className="flex flex-col md:text-left text-center w-[350px] border-r-4 border-blue-violet gap-5">
                                <div className="flex flex-col cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("angkasa-pura")}>
                                    Angkasa Pura II
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("check-dft")}>
                                        Check dft of Roughness Profile
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cool-cikarang-listrindo")}>
                                        Cooling Tower Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("fresh-water")}>
                                        Fresh Water Tank Angkasa Pura II
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press-internal")}>
                                        Press Film Process Internal Tank
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press")}>
                                        Press Film Process
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cikarang-listrindo")}>
                                        Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cbs")}>
                                        CBS Marunda Center
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("pundi")}>
                                        Silo PT. Pundi Kencana
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB] font-bold" onClick={() => setProject("moni-narogong")}>
                                        Storage Tank PT. Moni Narogong
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("lubuk-gaung")}>
                                        Storage Tank Smart Tbk Lubuk Gaung
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("marunda")}>
                                        Storage Tank Smart Tbk Marunda
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("dover")}>
                                        PT. Dover Chemical
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col md:text-left text-center gap-5 justify-center items-center">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/moni.png" alt="pressinter" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/moni2.png" alt="pressinter" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/moni3.png" alt="pressinter" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/moni4.png" alt="pressinter" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DefaultAnimation>
                </Container>
            </section>
        );
    } else if (project === 'lubuk-gaung') {
        return (
            <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
                <Container>
                    <DefaultAnimation>
                        <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                            <div className="flex justify-center items-center w-fit ">
                                <h2>{lang_obj.ProjectGalery}</h2>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-20">
                            <div className="flex flex-col md:text-left text-center w-[350px] border-r-4 border-blue-violet gap-5">
                                <div className="flex flex-col cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("angkasa-pura")}>
                                    Angkasa Pura II
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("check-dft")}>
                                        Check dft of Roughness Profile
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cool-cikarang-listrindo")}>
                                        Cooling Tower Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("fresh-water")}>
                                        Fresh Water Tank Angkasa Pura II
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press-internal")}>
                                        Press Film Process Internal Tank
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press")}>
                                        Press Film Process
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cikarang-listrindo")}>
                                        Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cbs")}>
                                        CBS Marunda Center
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("pundi")}>
                                        Silo PT. Pundi Kencana
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("moni-narogong")}>
                                        Storage Tank PT. Moni Narogong
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB] font-bold" onClick={() => setProject("lubuk-gaung")}>
                                        Storage Tank Smart Tbk Lubuk Gaung
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("marunda")}>
                                        Storage Tank Smart Tbk Marunda
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("dover")}>
                                        PT. Dover Chemical
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col md:text-left text-center gap-5 justify-center items-center">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/lubuk.png" alt="lubuk" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/lubuk2.png" alt="lubuk" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/lubuk3.png" alt="lubuk" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/lubuk4.png" alt="lubuk" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/lubuk5.png" alt="lubuk" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DefaultAnimation>
                </Container>
            </section>
        );
    } else if (project === 'marunda') {
        return (
            <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
                <Container>
                    <DefaultAnimation>
                        <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                            <div className="flex justify-center items-center w-fit ">
                                <h2>{lang_obj.ProjectGalery}</h2>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-20">
                            <div className="flex flex-col md:text-left text-center w-[350px] border-r-4 border-blue-violet gap-5">
                                <div className="flex flex-col cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("angkasa-pura")}>
                                    Angkasa Pura II
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("check-dft")}>
                                        Check dft of Roughness Profile
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cool-cikarang-listrindo")}>
                                        Cooling Tower Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("fresh-water")}>
                                        Fresh Water Tank Angkasa Pura II
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press-internal")}>
                                        Press Film Process Internal Tank
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press")}>
                                        Press Film Process
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cikarang-listrindo")}>
                                        Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cbs")}>
                                        CBS Marunda Center
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("pundi")}>
                                        Silo PT. Pundi Kencana
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("moni-narogong")}>
                                        Storage Tank PT. Moni Narogong
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("lubuk-gaung")}>
                                        Storage Tank Smart Tbk Lubuk Gaung
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB] font-bold" onClick={() => setProject("marunda")}>
                                        Storage Tank Smart Tbk Marunda
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("dover")}>
                                        PT. Dover Chemical
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col md:text-left text-center gap-5 justify-center items-center">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/maru2.png" alt="maru" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/maru3.png" alt="maru" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/maru4.png" alt="maru" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DefaultAnimation>
                </Container>
            </section>
        );
    } else if (project === 'dover') {
        return (
            <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
                <Container>
                    <DefaultAnimation>
                        <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                            <div className="flex justify-center items-center w-fit ">
                                <h2>{lang_obj.ProjectGalery}</h2>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-20">
                            <div className="flex flex-col md:text-left text-center w-[350px] border-r-4 border-blue-violet gap-5">
                                <div className="flex flex-col cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("angkasa-pura")}>
                                    Angkasa Pura II
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("check-dft")}>
                                        Check dft of Roughness Profile
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cool-cikarang-listrindo")}>
                                        Cooling Tower Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("fresh-water")}>
                                        Fresh Water Tank Angkasa Pura II
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press-internal")}>
                                        Press Film Process Internal Tank
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("press")}>
                                        Press Film Process
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cikarang-listrindo")}>
                                        Cikarang Listrindo
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("cbs")}>
                                        CBS Marunda Center
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("pundi")}>
                                        Silo PT. Pundi Kencana
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("moni-narogong")}>
                                        Storage Tank PT. Moni Narogong
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("lubuk-gaung")}>
                                        Storage Tank Smart Tbk Lubuk Gaung
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB]" onClick={() => setProject("marunda")}>
                                        Storage Tank Smart Tbk Marunda
                                    </p>
                                </div>
                                <div className="flex flex-col">
                                    <p className="cursor-pointer hover:text-[#6EB5CB] font-bold" onClick={() => setProject("dover")}>
                                        PT. Dover Chemical
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col md:text-left text-center gap-5 justify-center items-center">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/dover.png" alt="dover" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/dover2.png" alt="dover" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/dover3.png" alt="dover" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/dover4.png" alt="dover" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/dover5.png" alt="dover" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/dover6.png" alt="dover" />
                                    </div>
                                    <div className="w-full flex justify-center items-center overflow-hidden h-[250px] md:h-[250px] w-[250px] md:w-[250px] bg-slate-400">
                                        <img src="/images/page_galery/dover7.png" alt="dover" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DefaultAnimation>
                </Container>
            </section>
        );
    }
};

export default Service;
