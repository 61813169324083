import { usePrismicDocumentByUID } from "@prismicio/react";
import React from "react";
import { useSelector } from "react-redux";
import Header from "./Header";
import Service from "./Service";
import Clients from "./Clients";

const Home = () => {
  const { lang } = useSelector((state) => state.lang);
  const [document] = usePrismicDocumentByUID("landing_page_v01", "landing-page", {
    lang: lang === "ID" ? "id" : "en-us",
  });

  const services = document?.data.body[0];
  const clients = document?.data.body[1];

  return (
    <div className="snap-y snap-mandatory">
      <Header />
      <Service sectiondata={services} />
      <Clients sectiondata={clients} />
    </div>
  );
};

export default Home;
