import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import pageLink from "../../../data/pageLink.json";
import "./styles.css";

import Toggle from "./Toggle";

const Navbar = () => {
  const { lang_obj } = useSelector((state) => state.lang);

  const [toggel, setoggle] = useState("");
  const [togglebar, settogglebar] = useState(false);

  const menuref = useRef();
  const location = useLocation();

  // const src_bg = "'../public/images/heading.png'";
  const src_bg = null;
  const bg_tailwind = src_bg ? "bg-[url(" + src_bg + ")]" : "bg-white";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuref.current &&
        !menuref.current.contains(event.target) &&
        event.target.id !== "nestedlink" &&
        event.target.id !== "lang"
      ) {
        setoggle("");
        settogglebar(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => document.removeEventListener("click", handleClickOutside, true);
  }, []);

  return (
    <nav
      className={
        bg_tailwind +
        ` bg-no-repeat bg-center bg-cover bg-fixed fixed top-0 w-full border-b border-gray-200 z-[53] h-[70px]`
      }
    >
      <div className="flex flex-row justify-between items-center text-base h-full">
        <div className={bg_tailwind + " bg_responsive"}>
          <a href={"/"} className="shrink-0">
            <img
              src={process.env.PUBLIC_URL + "/icons/logo_comp.png"}
              alt="logo"
              className="h-[30px] w-auto object-contain"
            />
          </a>
          <FontAwesomeIcon
            icon={faBars}
            ref={menuref}
            className="bar_menu"
            onClick={() => {
              setoggle("");
              settogglebar(!togglebar);
            }}
          />
        </div>

        <ul className={`dashboard ${togglebar ? "active" : ""}`}>
          {pageLink.map((page, index) => (
            <li
              key={index}
              className="w-full relative"
              onClick={() => (page.nestedlink && page.name !== toggel ? setoggle(page.name) : setoggle(""))}
            >
              <Link to={!page.nestedlink && page.link}>
                <div
                  id={page.nestedlink ? "nestedlink" : ""}
                  className={`${
                    page.link === location.pathname ? "text-[#0072BC]" : "hover:text-[#6EB5CB]"
                  } customli  font-bold `}
                >
                  {lang_obj[page.name]}
                  <span className="ml-2">{page.nestedlink && <FontAwesomeIcon icon={faCaretDown} />}</span>
                </div>
              </Link>

              {toggel === page.name && (
                <div className="containersubmenu">
                  <ul className="lg:flex flex-col w-full">
                    {page.nestedlink.map((pagenested, index) => (
                      <li key={index}>
                        <a href={pagenested.link}>
                          <div className="px-3 py-3 whitespace-nowrap hover:bg-[#ebebeb] text-[#333333] cursor-pointer hover:text-[#6EB5CB]">
                            {lang_obj[pagenested.name]}
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
          <Toggle />
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
