import { configureStore } from "@reduxjs/toolkit";
import langReducer from "../reducers/lang.reducer";
import postReducer from "../reducers/post.reducer";
import todoReducer from "../reducers/todo.reducer";

export const store = configureStore({
  reducer: {
    posts: postReducer,
    todos: todoReducer,
    lang: langReducer,
  },
});
