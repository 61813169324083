import React from "react";
import Container from "../../../components/common/Container";
import DefaultAnimation from "../../../components/common/DefaultAnimation";
import { useSelector } from "react-redux";

const Service = () => {
    const { lang_obj } = useSelector((state) => state.lang);

    return (
        <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
            <Container>
                <DefaultAnimation>
                    <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                        <div className="flex justify-center items-center w-fit ">
                            <h2>{lang_obj.Legal}</h2>
                        </div>
                    </div>

                    <div className="flex flex-col justify-center md:flex-row gap-5 xl:gap-20">
                        <div className="flex flex-col gap-5">
                            <ul>
                                <li className="font-bold">
                                    Akta Notaris
                                </li>
                                <li>
                                    OLIA SYAFINI, S.H. No. 04, 10 Desember 2009
                                </li>
                            </ul>
                            <ul>
                                <li className="font-bold">
                                    Nomor Induk Berusaha
                                </li>
                                <li>
                                    0220508841536 UU Nomor 11 Tahun 2020
                                </li>
                            </ul>
                            <ul>
                                <li className="font-bold">
                                    Surat Keputusan Menteri Kehakiman
                                </li>
                                <li>
                                    No. AHU-62493.AH.01.01.Tahun 2009
                                </li>
                                <li>
                                    Status Penanaman Modal : PMDN
                                </li>
                            </ul>
                        </div>

                        <div className="flex flex-col gap-5">
                            <ul>
                                <li className="font-bold">
                                    Perubahan Anggaran Dasar dan Perubahan Data Perseroan
                                </li>
                                <li>
                                    No. AHU-AH.01-03-0978818
                                </li>
                                <li>
                                    No. AHU-AH.01.03-0978819
                                </li>
                            </ul>
                            <ul>
                                <li className="font-bold">
                                    Perubahan Anggaran Dasar dan Perubahan Data Perseroan
                                </li>
                                <li>
                                    No. AHU-AH.01.03-0344793
                                </li>
                                <li>
                                    No. AHU-AH.01.03-0344794
                                </li>
                            </ul>
                            <ul>
                                <li className="font-bold">
                                    Asosiasi Kontraktor Seluruh Indonesia (AKSI)
                                </li>
                                <li>
                                    Kartu Tanda Anggota Nomor : 170.10.16.031
                                </li>
                            </ul>
                        </div>
                    </div>
                </DefaultAnimation>
            </Container>
        </section>
    );
};

export default Service;
