import React from "react";

const PageNotFound = () => {
  return (
    <div className="flex h-screen w-screen justify-center items-center flex-col">
      <h1 className="text-slate-500 mb-4">404 Error - PAGE NOT FOUND</h1>
      {/* <p>this page is not found. it would redirect to home page soon...</p> */}
    </div>
  );
};

export default PageNotFound;
