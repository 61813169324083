import { usePrismicDocumentByUID } from "@prismicio/react";
import React from "react";
import { useSelector } from "react-redux";
import Container from "../../components/common/Container";

const BusinessPartner = () => {
    const { lang_obj } = useSelector((state) => state.lang);
    const [document, { state }] = usePrismicDocumentByUID("landing_page_v01", "landing-page");

    return (
        <>
            <section id="listnews">
                <Container bg={"bg-[#F9F9F9]"}>
                    <div className="mb-[20px] md:mb-[80px] flex flex-col justify-center items-center">
                        <h3 className="text-2xl md:text-3xl font-bold mb-[50px] text-[#1E3D72] m-auto text-center">
                            {lang_obj.OurClient}
                        </h3>
                        <div className="w-full flex flex-wrap md:flex-row justify-center gap-8">
                            {state !== "loaded" && <p>loading...</p>}
                            {state === "loaded" &&
                                document.data.body[1].items.map((slideImage, index) => (
                                    <div
                                        key={index}
                                        className="bg-white shadow-md rounded-xl h-[130px] w-[130px] flex flex-col justify-center items-center overflow-hidden"
                                    >
                                        <img
                                            src={slideImage["client-image"].url}
                                            alt="Client"
                                            className="object-cover w-full"
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default BusinessPartner;
