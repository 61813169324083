import React from "react";
import { Link } from "react-router-dom";
import Container from "../../../components/common/Container";
import DefaultAnimation from "../../../components/common/DefaultAnimation";

const Service = ({ sectiondata }) => {
    return (
        <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
            <Container>
                <DefaultAnimation>
                    <h3 className="mt-[65px] mb-[40px]">{sectiondata && sectiondata.primary["service-header-title"][0].text}</h3>
                    <div className="bg-white rounded-3xl mx-0.5 container flex justify-center gap-8 p-4 flex-wrap m-auto">
                        {sectiondata &&
                            sectiondata.items.map((detail, index) => (
                                <div
                                    key={index}
                                    className="relative flex justify-center items-center bg-slate-300 rounded-lg w-full sm:w-[250px] h-[250px] sm:h-[350px] overflow-hidden shadow-lg "
                                >
                                    <img src={detail["service-picture"].url} alt={detail["service-picture"].alt} className="object-cover w-full z-0" />
                                    <div className="absolute w-full h-full bg-gradient-to-t from-slate-900 opacity-50" />
                                    <div className="absolute h-full w-full p-4 flex justify-start items-end z-10">
                                        <Link to="/product">
                                            <h4 className="font-bold text-2xl text-white [text-shadow:#1E3D72_2px_3px_0px] hover:text-[#6EB5CB]">
                                                {detail["service-title"][0].text}
                                            </h4>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                    </div>
                </DefaultAnimation>
                <br />
            </Container>
        </section>
    );
};

export default Service;
