import { createSlice } from "@reduxjs/toolkit";
import { addtodos, fetchtodo, fetchtodobyid } from "../actions/todo.action";

const initialState = {
  todos: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

const todosSlice = createSlice({
  name: "todos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchtodo.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchtodo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.todos.push(...action.payload);
      })
      .addCase(fetchtodobyid.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchtodobyid.fulfilled, (state, actions) => {
        state.status = "succeeded";
        state.todos = [...actions.payload];
      })
      .addCase(addtodos.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addtodos.fulfilled, (state, action) => {
        state.status = "succeeded";
      });
  },
});

export default todosSlice.reducer;
