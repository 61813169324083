import React from "react";

const Header = () => {
    return (
        <div className="text-white text-2xl relative h-[100px] md:h-fit md:max-h-[94vh] flex justify-center items-center overflow-hidden">
            <img src="/images/page_product/Header_Constractor.png" alt="Header" className="w-screen object-cover" />
        </div>
    );
};

export default Header;
