import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import FloatingBtn from "../common/FloatingBtn";

const Layout = ({ children }) => {
  return (
    <div className="relative bg-body-bg flex flex-col overflow-x-hidden">
      <Navbar />
      <main id="top">
        <div className="h-[70px]"></div>
        <Outlet />
        <FloatingBtn />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
