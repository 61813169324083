import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";


import Container from "../Container";

const Footer = () => {
  const { lang_obj } = useSelector((state) => state.lang);

  return (
    <footer className="text-white bg-blue-violet w-full border-t border-gray-200 z-[50] h-fit mt-auto">
      <Container>
        <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8">
          <div className="flex justify-center items-center w-fit ">
            <h1 className="font-bold mb-1 text-sm">PT RAMOS PUTRA PERTAMA </h1>
          </div>
        </div>

        <div className="flex flex-col justify-between md:flex-row">
          <div className="flex flex-col md:text-left text-center justify-around">
            <p>
              JL. Kancil V Blok C No. 43,
              <br />
              Cikarang Baru, Sertajaya-Cikarang Timur,
              <br />
              Bekasi, Jawa Barat 17550
            </p>
            <ul className="flex gap-3 mt-3 justify-center md:justify-start font-bold">
              <li className="hover:text-[#6EB5CB]">
                <a href={`tel:021-89325054`} className="whitespace-nowrap">
                  <span className="mr-2">
                    <FontAwesomeIcon icon={faPhone} className="text-blue-500" />
                  </span>
                  021-89325054
                </a>
              </li>
            </ul>
            <ul>
              <li className="hover:text-[#6EB5CB]">
                <a href={`mailto:blastingpaintingrpp@gmail.com`} className="whitespace-nowrap">
                  <span className="mr-2">
                    <FontAwesomeIcon icon={faEnvelope} className="text-blue-500" />
                  </span>
                  blastingpaintingrpp@gmail.com
                </a>
              </li>
            </ul>
          </div>

          <div className="flex flex-col md:items-end justify-center items-center mt-8 md:mt-0">
            <ul className="flex gap-3 mb-2">
              <li><a href={"/about"} className="shrink-0 hover:text-[#6EB5CB]">{lang_obj.AboutFooter}</a></li>
            </ul>
            <ul className="flex gap-3 mb-2">
              <li><a href={"/product"} className="shrink-0 hover:text-[#6EB5CB]">{lang_obj.ProductFooter}</a></li>
            </ul>
            <ul className="flex gap-3 mb-2">
              <li><a href={"/legality"} className="shrink-0 hover:text-[#6EB5CB]">{lang_obj.LegalityFooter}</a></li>
            </ul>
            <ul className="flex gap-3 mb-2">
              <li><a href={"/Contact"} className="shrink-0 hover:text-[#6EB5CB]">{lang_obj.ContactFooter}</a></li>
            </ul>
          </div>
        </div>

        <div className="flex flex-wrap justify-center md:justify-between items-center text-center border-t border-gray-400 mt-4 pt-2">
          <p className="font-light">
            © {new Date().getFullYear()} copyright by PT. Ramos Putra Pertama
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
