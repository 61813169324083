import React from "react";
import { Link } from "react-router-dom";
import Container from "../../../components/common/Container";
import DefaultAnimation from "../../../components/common/DefaultAnimation";
import { motion } from "framer-motion";

const MySlider = ({ listclient }) => {
  const [widthcomp, setwidthcomp] = React.useState(0);
  const [animationStats, setAnimationStats] = React.useState(true);
  const [listClient, setClient] = React.useState(listclient);

  const ref = React.useRef();

  React.useEffect(() => {
    setwidthcomp(ref.current.offsetWidth);
  }, []);

  const handleAnimationComplete = () => {
    if (animationStats) {
      setTimeout(() => {
        const firts = listClient.shift();
        setClient([...listClient, firts]);
      }, 1);
    }
    setAnimationStats(!animationStats);
  };

  const variants = {
    start: { x: (widthcomp + 64) * -1, transition: { delay: 2, ease: "easeInOut", duration: 0.7 } },
    hide: { x: 0, transition: { duration: 0 } },
  };

  return (
    <motion.div
      key={listClient}
      initial="hide"
      animate={animationStats ? "start" : "hide"}
      variants={variants}
      className="flex flex-row justify-start gap-16 p-4 w-full mb-4"
      onAnimationComplete={handleAnimationComplete}
    >
      {listClient.map((slideImage, index) => {
        return (
          <div
            className="bg-white shrink-0 w-[120px] h-[120px] rounded-xl shadow-lg flex justify-center items-center overflow-hidden"
            key={index}
            ref={ref}
          >
            <img
              src={slideImage["client-image"].url}
              alt={"img_" + slideImage["client-image"].url}
              className="object-cover"
            />
          </div>
        );
      })}
    </motion.div>
  );
};

const Clients = ({ sectiondata }) => {
  return (
    <section>
      <Container>
        <DefaultAnimation>
          <div className="flex flex-col justify-center items-center mb-8 overflow-hidden">
            <h3 className="mb-1">{sectiondata && sectiondata.primary["client-header-title"][0].text}</h3>
            {sectiondata ? <MySlider listclient={sectiondata.items} /> : <p>loading...</p>}
            <Link to="/client"><p className="text-blue-violet hover:text-[#6EB5CB] cursor-pointer">Load more</p></Link>
          </div>
        </DefaultAnimation>
      </Container>
    </section>
  );
};

export default Clients;
