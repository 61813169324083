import React from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { getLangObj } from "./redux/actions/lang.action";
import RouteApp from "./routes";
import ScrollTop from "./routes/ScrollTop";

function App() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getLangObj());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <ScrollTop />
      <RouteApp />
    </BrowserRouter>
  );
}

export default App;
