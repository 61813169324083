import React from "react";
import Container from "../../../components/common/Container";
import DefaultAnimation from "../../../components/common/DefaultAnimation";
import { useSelector } from "react-redux";

const Service = () => {
    const { lang_obj } = useSelector((state) => state.lang);

    return (
        <section className="bg-body-bg rounded-tr-[55px] rounded-tl-[55px] mt-[-55px]">
            <Container>
                <DefaultAnimation>
                    <div className="flex flex-wrap flex-col justify-center items-center md:justify-between md:flex-row gap-4 mb-8 mt-10">
                        <div className="flex justify-center items-center w-fit ">
                            <h2>{lang_obj.WorkGalery}</h2>
                        </div>
                    </div>

                    <div className="bg-white flex justify-center gap-8 p-4 flex-wrap">
                        <div className="flex flex-col justify-center items-center min-h-[35vh] mt-8">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
                                <div className="flex flex-col w-full aspect-video gap-4 group">
                                    <div className="w-full flex justify-center items-center rounded-3xl overflow-hidden h-[230px] md:h-[200px] bg-slate-400">
                                        <img src="/images/page_about/workgalery1.jpeg" alt="WorkingGalery"/>
                                    </div>
                                </div>
                                <div className="flex flex-col w-full aspect-video gap-4 group">
                                    <div className="w-full flex justify-center items-center rounded-3xl overflow-hidden h-[230px] md:h-[200px] bg-slate-400">
                                        <img src="/images/page_about/workgalery2.jpeg" alt="WorkingGalery"/>
                                    </div>
                                </div>
                                <div className="flex flex-col w-full aspect-video gap-4 group">
                                    <div className="w-full flex justify-center items-center rounded-3xl overflow-hidden h-[230px] md:h-[200px] bg-slate-400">
                                        <img src="/images/page_about/workgalery3.jpeg" alt="WorkingGalery"/>
                                    </div>
                                </div>
                                <div className="flex flex-col w-full aspect-video gap-4 group">
                                    <div className="w-full flex justify-center items-center rounded-3xl overflow-hidden h-[230px] md:h-[200px] bg-slate-400">
                                        <img src="/images/page_about/workgalery4.jpeg" alt="WorkingGalery"/>
                                    </div>
                                </div>
                                <div className="flex flex-col w-full aspect-video gap-4 group">
                                    <div className="w-full flex justify-center items-center rounded-3xl overflow-hidden h-[230px] md:h-[200px] bg-slate-400">
                                        <img src="/images/page_about/workgalery5.jpeg" alt="WorkingGalery"/>
                                    </div>
                                </div>
                                <div className="flex flex-col w-full aspect-video gap-4 group">
                                    <div className="w-full flex justify-center items-center rounded-3xl overflow-hidden h-[230px] md:h-[200px] bg-slate-400">
                                        <img src="/images/page_about/workgalery6.jpeg" alt="WorkingGalery"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DefaultAnimation>
            </Container>
        </section>
    );
};

export default Service;
