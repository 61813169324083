import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../components/Layout";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import Legality from "../pages/Legality";
import Galery from "../pages/Galery";
import Product from "../pages/Product";
import Client from "../pages/Client";
import PageNotFound from "../pages/PageNotFound";

const RouteApp = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="product" element={<Product />} />
        <Route path="legality" element={<Legality />} />
        <Route path="galery" element={<Galery />} />
        <Route path="contact" element={<Contact />} />
        <Route path="client" element={<Client />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default RouteApp;
