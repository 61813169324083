import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLang } from "../../../redux/actions/lang.action";

const Toggle = () => {
  const { lang } = useSelector((state) => state.lang);
  const dispatch = useDispatch();

  const [langtoggel, setlangtoggle] = React.useState(false);

  React.useEffect(() => setlangtoggle(lang !== "ID"), [lang]);

  const handleChangelang = () => {
    setlangtoggle(!langtoggel);
    dispatch(setLang(langtoggel ? "ID" : "EN"));
  };

  return (
    <div className="toggle">
      <label htmlFor="lang" className="inline-flex relative items-center cursor-pointer">
        <input id="lang" type="checkbox" className="sr-only peer" onChange={handleChangelang} checked={langtoggel} />
        <div
          id="lang"
          className={`w-[60px] h-8 bg-[#0072BC] rounded-full peer peer-focus:ring-0 peer-checked:after:translate-x-full peer-checked:after:border-white  after:absolute after:top-0.5 after:left-[2px] after:flex after:justify-center after:items-center after:bg-white after:border-gray-300 after:bg-opacity-80 after:border after:rounded-full after:h-7 after:w-7 after:transition-all after:font-bold after:text-slate-600 flex justify-center items-center gap-3 z-10 after:content-['']`}
        >
          <p id="lang" className={`${langtoggel ? "text-slate-400" : "text-slate-700"} font-bold z-50`}>
            ID
          </p>
          <p id="lang" className={`${langtoggel ? "text-slate-700" : "text-slate-400"} font-bold z-50`}>
            EN
          </p>
        </div>
      </label>
    </div>
  );
};

export default Toggle;
