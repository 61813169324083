import { createSlice } from "@reduxjs/toolkit";
import { addpost, fetchpost, fetchpostbyid } from "../actions/post.action";

const initialState = {
  posts: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchpost.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchpost.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.posts = [...action.payload];
      })
      .addCase(fetchpostbyid.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchpostbyid.fulfilled, (state, actions) => {
        state.status = "succeeded";
        state.posts = [...actions.payload];
      })
      .addCase(addpost.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addpost.fulfilled, (state) => {
        state.status = "succeeded";
      });
  },
});

export default postsSlice.reducer;
