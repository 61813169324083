import { createSlice } from "@reduxjs/toolkit";
import { getLangObj, setLang } from "../actions/lang.action";

const initialState = {
  lang_obj: {},
  lang: "ID", //'ID' | 'EN',
};

const languageSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLangObj.fulfilled, (state, action) => {
        state.lang = action.payload.lang;
        state.lang_obj = action.payload.langObj;
      })
      .addCase(setLang.fulfilled, (state, action) => {
        state.lang = action.payload.lang;
        state.lang_obj = action.payload.langObj;
      });
  },
});

export default languageSlice.reducer;
