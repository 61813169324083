import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReduxProvider from "./redux";
import { PrismicProvider } from "@prismicio/react";
import { client } from "./api/prismic.api";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReduxProvider>
    <PrismicProvider client={client}>
      <App />
    </PrismicProvider>
  </ReduxProvider>
);
