import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Header = () => {
    const { lang_obj } = useSelector((state) => state.lang);

    return (
        <div className="text-white md:text-xs lg:text-2xl relative h-[100px] md:h-fit md:max-h-[94vh] flex justify-center items-center overflow-hidden">
            <img src="/images/page_home/Header_Home.png" alt="HomeHeader" className="w-screen object-cover" />
            <Link to="/product"><button className="absolute left-10 rounded-full bg-blue-900 hover:bg-indigo-900 bg-opacity-75 lg:h-[75px] lg:w-[250px]">{lang_obj.ExploreNow}</button></Link>
        </div>
    );
};

export default Header;
