import React from "react";
import SandblastingHeader from "./SandblastingHeader";
import Sandblasting from "./Sandblasting";
import ContractorsHeader from "./ContractorsHeader";
import Contractors from "./Contractors";
import FabricationHeader from "./FabricationHeader";
import Fabrication from "./Fabrication";
import TradingHeader from "./TradingHeader";
import Trading from "./Trading";

const Home = () => {
  return (
    <div className="snap-y snap-mandatory">
      <SandblastingHeader />
      <Sandblasting />
      <ContractorsHeader />
      <Contractors />
      <FabricationHeader />
      <Fabrication />
      <TradingHeader />
      <Trading />
    </div>
  );
};

export default Home;
