import axios from "axios";
import someDelay from "../utils/someDelay";

const POST_URL = "https://jsonplaceholder.typicode.com/posts";

export const getPosts = async (page = 0, limit = 10) => {
  try {
    const res = await axios.get(POST_URL + `?_limit=${limit}&_page=${page}`);
    await someDelay(5000);
    return res.data;
  } catch (error) {
    console.error(error.message);
  }
};

export const getPostsById = async (id) => {
  try {
    const res = await axios.get(POST_URL + `/${id}`);
    return res.data;
  } catch (error) {
    console.error(error.message);
  }
};

export const addPosts = async (payload) => {
  try {
    const res = await axios.post(POST_URL, {
      userId: payload.userid,
      title: payload.title,
      body: payload.body,
    });
    return res.data;
  } catch (error) {
    console.error(error.message);
  }
};
