import React from "react";
import Header from "./Header";
import About from "./About";
import GaleriKerja from "./GaleriKerja";

const Home = () => {
  return (
    <div className="snap-y snap-mandatory">
      <Header />
      <About />
      <GaleriKerja />
    </div>
  );
};

export default Home;
