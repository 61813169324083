import axios from "axios";
import someDelay from "../utils/someDelay";

const TODO_URL = "https://jsonplaceholder.typicode.com/todos";

export const getTodos = async (page = 0, limit = 10) => {
  try {
    const res = await axios.get(TODO_URL + `?_limit=${limit}&_page=${page}`);
    await someDelay(5000);
    return res.data;
  } catch (error) {
    console.error(error.message);
  }
};

export const getTodosById = async (id) => {
  try {
    const res = await axios.get(TODO_URL + `/${id}`);
    return res.data;
  } catch (error) {
    console.error(error.message);
  }
};

export const addTodos = async (payload) => {
  try {
    const res = await axios.post(TODO_URL, {
      userId: payload.userid,
      title: payload.title,
      completed: payload.complete,
    });
    return res.data;
  } catch (error) {
    console.error(error.message);
  }
};
