import React from "react";
import Header from "./Header";
import Galery from "./Galery";

const Home = () => {
  return (
    <div className="snap-y snap-mandatory">
      <Header />
      <Galery />
    </div>
  );
};

export default Home;
